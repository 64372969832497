import React ,{useEffect,useState}from "react";
import Banner from "../components/banner";
import BackgroundImage from "../components/background_image";
import CompraTickets from "../components/tickets_compra";
import Card from "../components/card_atracciones";
import Mapa from "../components/mapa";
import PreFooter from "../components/prefooter";
import NewsLetter from "../components/newsletter";
import MenuFooter from "../components/menu_footer";
import Politicas from "../components/politicas";
import Redes from "../components/redes";
import Copyright from "../components/copyright";
import HeaderCard from "../components/header_card";
import ContentCard from "../components/content_card";
import CardEvento from "../components/card_eventos";
import CardEventoContenido from "../components/card_evento_contenido";
import ContentInstagram from "../components/content_instragram";
import ReservacionesHotel from "../components/reservaciones_hotel";
import CollageGaleria from "../components/collage_galeria";
import $ from 'jquery';
import Tarifas from "../components/tarifas";
import CardComplementa from "../components/card_complementa";
import ImagenSitioInteres from "../components/imagen_sitio_interes";
import SitioInteres from "../components/sitio_de_interes";
import ComplementaVisita from "../components/complementa_visita";
import CarouselCardComplementa from "../components/carousel_card_complementa";
import CompraTicketsMobile from "../components/tickets_compra_mobile";
import FooterSocial from "../components/footer_social";
import CardCarrito from "../components/card_carrito";
import ListaCarrito from "../components/lista_carrito";
import * as app from "../fetch/fetchApi";

const Cart = () => {

  const [data, setData] = useState({})
  const [dataMenu, setDataMenu] = useState({})

  useEffect(() => {
    fetch(app.url+`/node-api/m22_item_menu/menu-foooter-menu-2019`)
      .then(response => response.json())
      .then(resultData => {
        console.log(resultData)
        setData(resultData)
      })
  }, [])

  useEffect(() => {
    fetch(app.url+`/node-api/m22_item_menu/main-menu`)
      .then(response => response.json())
      .then(resultData => {
        console.log(resultData)
        setDataMenu(resultData)
      })
  }, [])
  return (
    <div>
       <Banner data={dataMenu} />
    <footer className={'l-footer panel'} role={'contentinfo'}>
    <div className={'footer'}>
    <MenuFooter data={data}/>
    <Politicas />
    <Redes />
    </div>
    <Copyright />
</footer>
</div>
  );
};

export default Cart;