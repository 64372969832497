import * as React from "react"
import { Header } from "../layout/header"
import '../index.scss';
//import './sites/all/themes/tickets/js/estacas/form_tickets_rd.js';
import '../sites/all/themes/tickets/js/estacas/map.js';
import '../sites/all/themes/tickets/js/estacas/cards_rd'
//import './sites/all/themes/tickets/js/estacas/stickyfloat'
import '../sites/all/themes/tickets/js/estacas/eventos'
import '../sites/all/themes/tickets/js/estacas/form_tickets_rd'
//import './stylesheets/tickets.css'
//import './sites/all/themes/tickets/js/estacas/header_rd';
import '../sites/all/themes/tickets/js/estacas/instagram_posts';
import '../sites/all/themes/tickets/js/estacas/header_rd';
import { Helmet } from "react-helmet"

import { PlantillaHospedaje } from "../layout/plantillaHospedaje";
//import Cart from "../layout/cart"; "../layout/cart";
import Cart from "../layout/cart";
// markup
const Carrito = () => {
        return (
                <body className={'html front not-logged-in no-sidebars page-node page-node- page-node-1612 node-type-page lang-es'}>
                        <Helmet>

                        </Helmet>
                        <Cart />
                </body>
        )
}

export default Carrito
